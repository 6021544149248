#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

#instructions {
  position: absolute;
  margin: 20px;
  width: 25%;
  top: 0;
  bottom: 20%;
  padding: 20px;
  background-color: #fff;
  overflow-y: scroll;
  font-family: sans-serif;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
  /* display: block; */
}

.modal-y-axis {
  overflow-x: hidden;
  overflow-y: auto;
}

.bg-button {
  background: #fff;
}

.ul-css {
  list-style-type: none;
  padding: 0;
  border: 1px solid #ddd;
}

.ul-css .li-css {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}

.ul-css .li-css:last-child {
  border-bottom: none;
}

.navlink-custom-border {
  border-right: transparent;
  border-left: transparent;
  border-top: transparent;
  border-bottom: transparent;
  font-size: 16px;
}

.navlink-custom-border-active {
  border-bottom-color: #f69b31 !important;
  border-bottom-width: 2.5px !important;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  max-height: 300px;
}
ul.notification-list > li {
  margin-top: 0;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
  /* overflow:auto;
    max-height:300px; */
}
ul.notification-list > li:last-child {
  border-bottom: none;
}
ul.notification-list > li a {
  display: block;
  padding: 5px;
  border-radius: 2px;
}
ul.notification-list > li a:hover {
  background-color: #fafafa;
}
ul.notification-list > li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.noti-details {
  color: #282828;
  margin-bottom: 0;
}

.noti-time {
  font-size: 12px;
  color: #bdbdbd;
  margin: 0;
}

.avatar {
  background-color: #aaa;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 41px;
  line-height: 38px;
  margin: 0 10px 0 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 41px;
  position: relative;
  white-space: nowrap;
}

.main-btn2 {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
  padding: 0 42px;
  font-size: 18px;
  line-height: 60px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #2f2fe9;
}
/* .main-btn2:hover {
    border: 2px solid #2f2fe9;
    background-color: #fff;
} */
.main-btn2:hover {
  color: #fff !important;
}

.labels2 {
  color: white;
  background-color: black;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 12px;
  text-align: center;
  width: auto;
  white-space: nowrap;
  margin-top: -40px;
  padding: 1px 5px 1px 5px;
  /* padding-right: 5px;
    padding-left: 5px; */
}

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
}
.marker:hover {
  z-index: 1;
}
/* .features-section{
	position: relative;
	padding: 25%;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
	background-image: url(../images/Ridetifyloginbg.png);
    margin: 0;
} */
.features-section {
  padding: 20%;
  margin: 0;
  background-image: url(../images/Ridetifyloginbg.png);
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
}

/* .features-section::before{ */
/* background-color: #fff; */
/* background-position: right; */
/* background-image: url(../images/Ridetifyloginbg.png); */
/* content: ""; */
/* position: absolute; */
/* left: 0; */
/* bottom: 0; */
/* background-repeat: no-repeat; */
/* background-size: contain; */
/* width: 100%; */
/* top: 0; */
/* z-index: -1; */
/* background-size: 100% 100%; */
/* } */

/* body{background:#ecf0f1;}
a{text-decoration:none;} */
.firstLine {
  font-size: 20px;
  font-weight: 300;
}
.secondLine {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
}
.thirdLine {
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
}
.fourthLine {
  font-size: 12px;
  font-weight: 300;
  position: relative;
  top: -10px;
}

.badges {
  position: relative;
  margin: 140px auto;
  width: 200px;
  height: 200px;
  background: #2f2fe9;
  border-radius: 100%;
  color: #fff;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  -webkit-animation: 3s ease-in-out 0s normal none infinite running swing;
  -moz-animation: 3s ease-in-out 0s normal none infinite running swing;
  -o-animation: 3s ease-in-out 0s normal none infinite running swing;
  animation: 3s ease-in-out 0s normal none infinite running swing;

  -webkit-transform-origin: 100px -71px;
  -moz-transform-origin: 100px -71px;
  -o-transform-origin: 100px -71px;
  transform-origin: 100px -71px;
}
.badges:before {
  content: "";
  position: absolute;
  top: 90px;
  left: 90px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  -webkit-box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9,
    20px -98px #2f2fe9, 39px -94px #2f2fe9, 56px -85px #2f2fe9,
    71px -72px #2f2fe9, 83px -57px #2f2fe9, 93px -40px #2f2fe9,
    98px -20px #2f2fe9, 100px 0px #2f2fe9, -20px -98px #2f2fe9,
    -39px -94px #2f2fe9, -71px -72px #2f2fe9, -56px -85px #2f2fe9,
    -83px -57px #2f2fe9, -93px -40px #2f2fe9, -98px -20px #2f2fe9,
    -100px 0px #2f2fe9, 0px 100px #2f2fe9, -20px 98px #2f2fe9,
    -39px 94px #2f2fe9, -56px 85px #2f2fe9, -71px 72px #2f2fe9,
    -83px 57px #2f2fe9, -93px -40px #2f2fe9, -98px 20px #2f2fe9,
    -93px 40px #2f2fe9, 20px 98px #2f2fe9, 39px 94px #2f2fe9, 56px 85px #2f2fe9,
    71px 72px #2f2fe9, 83px 57px #2f2fe9, 93px 40px #2f2fe9, 98px 20px #2f2fe9;

  -moz-box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9,
    20px -98px #2f2fe9, 39px -94px #2f2fe9, 56px -85px #2f2fe9,
    71px -72px #2f2fe9, 83px -57px #2f2fe9, 93px -40px #2f2fe9,
    98px -20px #2f2fe9, 100px 0px #2f2fe9, -20px -98px #2f2fe9,
    -39px -94px #2f2fe9, -71px -72px #2f2fe9, -56px -85px #2f2fe9,
    -83px -57px #2f2fe9, -93px -40px #2f2fe9, -98px -20px #2f2fe9,
    -100px 0px #2f2fe9, 0px 100px #2f2fe9, -20px 98px #2f2fe9,
    -39px 94px #2f2fe9, -56px 85px #2f2fe9, -71px 72px #2f2fe9,
    -83px 57px #2f2fe9, -93px -40px #2f2fe9, -98px 20px #2f2fe9,
    -93px 40px #2f2fe9, 20px 98px #2f2fe9, 39px 94px #2f2fe9, 56px 85px #2f2fe9,
    71px 72px #2f2fe9, 83px 57px #2f2fe9, 93px 40px #2f2fe9, 98px 20px #2f2fe9;

  -o-box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9, 20px -98px #2f2fe9,
    39px -94px #2f2fe9, 56px -85px #2f2fe9, 71px -72px #2f2fe9,
    83px -57px #2f2fe9, 93px -40px #2f2fe9, 98px -20px #2f2fe9,
    100px 0px #2f2fe9, -20px -98px #2f2fe9, -39px -94px #2f2fe9,
    -71px -72px #2f2fe9, -56px -85px #2f2fe9, -83px -57px #2f2fe9,
    -93px -40px #2f2fe9, -98px -20px #2f2fe9, -100px 0px #2f2fe9,
    0px 100px #2f2fe9, -20px 98px #2f2fe9, -39px 94px #2f2fe9,
    -56px 85px #2f2fe9, -71px 72px #2f2fe9, -83px 57px #2f2fe9,
    -93px -40px #2f2fe9, -98px 20px #2f2fe9, -93px 40px #2f2fe9,
    20px 98px #2f2fe9, 39px 94px #2f2fe9, 56px 85px #2f2fe9, 71px 72px #2f2fe9,
    83px 57px #2f2fe9, 93px 40px #2f2fe9, 98px 20px #2f2fe9;

  box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9, 20px -98px #2f2fe9,
    39px -94px #2f2fe9, 56px -85px #2f2fe9, 71px -72px #2f2fe9,
    83px -57px #2f2fe9, 93px -40px #2f2fe9, 98px -20px #2f2fe9,
    100px 0px #2f2fe9, -20px -98px #2f2fe9, -39px -94px #2f2fe9,
    -71px -72px #2f2fe9, -56px -85px #2f2fe9, -83px -57px #2f2fe9,
    -93px -40px #2f2fe9, -98px -20px #2f2fe9, -100px 0px #2f2fe9,
    0px 100px #2f2fe9, -20px 98px #2f2fe9, -39px 94px #2f2fe9,
    -56px 85px #2f2fe9, -71px 72px #2f2fe9, -83px 57px #2f2fe9,
    -93px -40px #2f2fe9, -98px 20px #2f2fe9, -93px 40px #2f2fe9,
    20px 98px #2f2fe9, 39px 94px #2f2fe9, 56px 85px #2f2fe9, 71px 72px #2f2fe9,
    83px 57px #2f2fe9, 93px 40px #2f2fe9, 98px 20px #2f2fe9;
}
.badges:after {
  content: "";
  position: absolute;
  top: -70px;
  left: 99px;
  width: 2px;
  height: 81px;
  border-radius: 0%;
  background: #000;
}

@-webkit-keyframes swing {
  0% {
    -webkit-transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(5deg);
  }
}
@-moz-keyframes swing {
  0% {
    -moz-transform: rotate(5deg);
  }
  50% {
    -moz-transform: rotate(-5deg);
  }
  100% {
    -moz-transform: rotate(5deg);
  }
}
@-o-keyframes swing {
  0% {
    -o-transform: rotate(5deg);
  }
  50% {
    -o-transform: rotate(-5deg);
  }
  100% {
    -o-transform: rotate(5deg);
  }
}
@keyframes swing {
  0% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

.circle p {
  border: 4px double #f69b31;
  padding: 15px 0px;
  width: 110px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.payment-price {
  position: absolute;
  left: 65px;
  width: 131px;
  top: 20px;
}

.mile-text {
  border-left: 4px double #f69b31;
  margin-left: 55px;
  margin-top: -16px;
}
.mile-text p {
  writing-mode: vertical-lr;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
}

.mile-text2 {
  border: none;
  margin-left: 10px;
  /* margin-top: -146px; */
  margin-top: -100%;
}
.mile-text2 p {
  writing-mode: vertical-lr;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
}

.col-7.mitbsnav {
  /* margin-left: 180px; */
  margin-left: 20%;
  /* align-items: center ; */
  text-align: center;
}

/* .col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
} */

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  border-radius: 2px;
  /* border-radius: 0 5px 5px 0; */
}

.mitbsnav ul li.active {
  background-color: #f69b31;
}

.mitbsnav ul li:first-child {
  border-radius: 2px;
  /* border-right: none; */
}

.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.mitbsnav ul li {
  border: 1px solid #f69b31;
  padding: 5px 0;
  color: #f69b31;
  background-color: #fff;
  font-size: 14px;
}

.align-items-center {
  align-items: center !important;
}

.mitbsnav ul li a {
  color: #f69b31;
}

.mitbsnav ul li.active a {
  color: #fff;
}

.container-custom {
  position: fixed;
  /* right: 0; */
  left: 0 !important;
  top: 0px !important;
  width: 100% !important;
  /* z-index: 9999; */
  background: #f2f4f7;
  /* margin-left: 240px; */
  /* margin-top: 63px; */
  min-height: 100vh !important;
}

.border-bottom-black {
  border-bottom: 1px solid #808080;
}

.custom-badge {
  background-color: #fc6075;
  color: #fff;
  font-weight: 700;
  position: absolute;
  right: 10px;
  top: -8px;
}

.accordion-button::after {
  display: block;
}

.topnav-dropdown-footer a {
  /* display: block; */
  /* text-align: center; */
  /* color: #333; */
  color: #252d37;
  /* color: #1c4a85; */
  font-size: 13px !important;
}

.active-3 {
  color: #28a2a9 !important;
}
.home-col {
  color: #f69b31 !important;
}

.form-controlnew {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-controlnew:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn-secondarynew {
  /* background: #293E4A; */
  background: #3894bb;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-transform: uppercase;
  /* color: #F69B31; */
  color: #143443;
}

.btn-secondarynew:hover {
  /* background: #293E4A; */
  background: #3894bb;
  border-radius: 6px;
  /* color: #F69B31; */
  color: #143443;
}

.profile-view .pro-edit {
  position: absolute;
  right: 0;
  top: 0;
}
.edit-icon {
  background-color: #eee;
  border: 1px solid #e3e3e3;
  border-radius: 24px;
  color: #606060;
  float: right;
  font-size: 12px;
  line-height: 24px;
  min-height: 26px;
  text-align: center;
  width: 26px;
}
.edit-icon:hover {
  /* background-color: #ff9b44; */
  /* border-color: #ff9b44; */
  color: #fff;
}

.fileupload.btn {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(33, 33, 33, 0.5);
  border-radius: 0;
  padding: 3px 10px;
  border: none;
}
.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: -3px;
  top: -3px;
  padding: 5px;
}

.profile-img-wrap.edit-img .fileupload.btn {
  left: 0;
}
.profile-img-wrap {
  height: 120px;
  position: absolute;
  width: 120px;
  background: #fff;
  overflow: hidden;
}

.profile-img-wrap img {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}
.profile-img-wrap.edit-img {
  border-radius: 50%;
  /* margin: 0 auto 30px; */
  position: relative;
}
.profile-img-wrap {
  position: relative;
  margin: 0 auto;
}
.profile-view .profile-img-wrap {
  height: 180px;
  width: 120px;
}
.btn-text {
  color: #fff;
}
.inline-block {
  border: solid 1px #b0b0b0;
}

table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
table.table td h2.table-avatar.blue-link a {
  color: #007bff;
}

.avatarr {
  /* background-color: #aaa; */
  /* border-radius: 50%; */
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 38px;
  line-height: 38px;
  margin: 0 10px 0 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 38px;
  position: relative;
  white-space: nowrap;
}

.mail-view-title {
  font-weight: 400;
  font-size: 32px;
  margin: 0;
}
.mail-view-action {
  float: right;
}
.mail-sent-time {
  float: right;
}
.sender-name {
  display: block;
  font-weight: 600;
}
.receiver-name {
  color: #777;
}
.mailview-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.mailview-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 15px;
}
.mailview-footer .btn-white {
  min-width: 102px;
}

.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}

.left-action {
  text-align: center;
  margin-bottom: 15px;
}

.pricing-6-item {
  border: 2px solid #293e4a;
  border-radius: 10px;
  box-shadow: 0 1px 5px 0 #293e4a;
  padding: 30px 30px 42px;
}

.pricing-6-item p:after {
  background: transparent;
  border-bottom: 1px dashed #1f72b0;
  content: "";
  display: table;
  height: 1px;
  left: 0;
  margin-top: 20px;
  position: relative;
  width: 100%;
}
.mail-date {
  text-align: right;
}
.unread .name,
.unread .subject,
.unread .mail-date {
  /* color: #000; */
  /* font-weight: 600; */
  background-color: #ddd;
  color: #000;
  font-weight: 700;
  font-family: Arial;
}

.bg-msgs {
  background-color: lightgray !important;
}

.sender-img {
  float: left;
  margin-right: 10px;
  /* width: 40px; */
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

/* CSS used here will be applied after bootstrap.css */

.dropdown3 {
  display: inline-block;
  margin-left: 20px;
  padding: 10px;
}

.glyphicon-bell {
  font-size: 1.5rem;
}

.notifications3 {
  min-width: 420px;
}

.notifications3-wrapper {
  overflow: auto;
  max-height: 250px;
}

.menu-title {
  color: #ff7788;
  font-size: 1.5rem;
  display: inline-block;
}

.glyphicon-circle-arrow-right {
  margin-left: 10px;
}

.notification-heading,
.notification-footer3 {
  padding: 2px 10px;
}

.dropdown3-menu.divider {
  margin: 5px 0;
}

.item-title {
  font-size: 1.3rem;
  color: #000;
}

.notifications3 a.content {
  text-decoration: none;
  background: #ccc;
}

.notification-item3 {
  padding: 10px;
  margin: 5px;
  background: #ccc;
  border-radius: 4px;
}

.activee {
  background-color: black !important;
  border: solid 1px #000 !important;
  color: #fff !important;
}

/* chat css */
.gap-2 {
  gap: 0.5rem !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.me-3-wala-new {
  margin-right: 1rem !important;
}

.card-bodyyy {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.page-wrapper {
  left: 0;
  margin-left: 230px;
  /* padding-top: 60px; */
  position: relative;
  transition: all 0.2s ease-in-out;
}

.page-wrapper > .content {
  padding: 30px;
}

.page-wrapper.job-wrapper {
  margin-left: 0;
}

/* @media only screen and (min-width: 991px) {
    .page-wrapper {
        margin-left: 60px;
    }
} */

@media only screen and (max-width: 991.98px) {
  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .page-wrapper {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
}

@media only screen and (max-width: 575.98px) {
  .page-wrapper > .content {
    padding: 15px;
  }
}

.pad_left {
  padding-left: 1.5rem;
}

.chat-message-cont {
  min-width: 170px !important;
  max-width: 75% !important;
}
.bg-gray {
  background-color: #e9e9e9;
}
.modal-body {
  height: fit-content !important;
}
.group-members-list {
  max-height: 220px;
  overflow-y: auto;
}
.close-new {
  background-color: #fff;
  /* border-radius: 50%; */
  /* color: #fff; */
  font-size: 18px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  z-index: 99;
}

.settings-box-custom {
  padding: 20px 15%;
}
.cont-bg-new {
  background-color: rgb(20, 52, 67);
  padding: 30px;
  border-radius: 20px;
}

.btn-secondaryyy {
  text-transform: capitalize;
  /* padding: 9px 11px; */
  background-color: #1d82f5 !important;
  color: #fff !important;
  border: 1px solid #1d82f5 !important;
  position: relative;
}

.right-sorting-new {
  float: right;
}

.preloader-container-new {
  /* position: fixed; */
  /* right: 0; */
  left: 0;
  top: 0px;
  /* width: calc(100% - 240px); */
  z-index: 9999;
  /* background: #F2F4F7; */
  /* margin-left: 240px; */
  /* margin-top: 63px; */
  /* min-height: calc(100vh - 63px); */
  min-height: 300px;
}

.centralized-avatar {
  justify-content: center;
  display: flex;
  align-items: center;
}

.extra-space {
  margin-bottom: 0.5em;
}

.extra-space-remove {
  margin-top: -1em;
}
.track-user-detail-card {
  margin-top: 20px;
}

.price-tag-detail {
  cursor: pointer;
  /* color: #00308F; */
}

.card-headertext-blue {
  color: #3894bb !important;
}
.text-black {
  color: #000 !important;
}
.text-black:hover {
  text-decoration: underline;
  color: #000 !important;
}

.centre-btn {
  margin-top: 20px;
  margin-left: 20px;
}

.driver-card {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.driver-card strong {
  display: block;
  margin-bottom: 5px;
}

/* CSS styles for circle-container */
.circle-container {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

/* CSS styles for circle */
.circle {
  width: 30px; /* Adjust the size as needed */
  height: 30px; /* Adjust the size as needed */
  border-radius: 50%;
  margin-right: 10px; /* Adjust the spacing between circles */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px; /* Adjust the font size as needed */
  font-weight: bold;
  color: #fff; /* Adjust the text color */
}

.driver-card-2 {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 10rem;
  top: 2rem;
}

.circle-pricing p {
  border: 3px double #f69b31;
  padding: 15px 0px;
  width: 110px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.pricing-modal-input {
  width: 10%;
}

.pricing-modal-input2 {
  width: 15%;
}

.pricing-modal-input3 {
  width: 30%;
}

.labeling-input {
  font-size: 12px;
  font-weight: 300;
  opacity: 1;
  top: 28px;
  left: 156px;
  position: absolute;
}

.input-wrapper {
  position: relative;
}

.pricing-modal-input4 {
  width: 100%;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  font-size: 14px;
}

.pricing-modal-input4:focus + label,
.pricing-modal-input4:valid + label {
  top: -15px;
  left: 2px;
  font-size: 12px;
  /* color: #555; */
}

.label22 {
  position: absolute;
  top: 10px;
  left: 10px;
  transition: all 0.3s ease;
  pointer-events: none;
  /* color: #777; */
  font-size: 14px;
}

.vl {
  border-left: 6px solid #28a745;
  /* position: absolute; */
  /* left: 50%; */
  /* margin-left: -3px; */
  /* top: 0; */
}

.btn-height {
  height: 45px;
}

.text-14-new {
  font-size: 14px !important;
}

.accordion-body {
  /* border: 1px solid grey; Grey border all around the accordion body */
  /* border-top: none; Remove top border */
  border-radius: 0 0 5px 5px; /* Rounded corners only on bottom */
  padding: 10px; /* Padding */
}

.newNavLink {
  background-color: #ffffff !important;
  color: #000 !important;
}

.navbar-nav .newNavLink {
  color: #000;
  font-family: "inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.7em;
  padding: 6px 15px !important;
}

.newNavLink:hover {
  font-family: "inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #28a2a9 !important;
  text-decoration: none !important;
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  a.newNavLink {
    margin-left: 0;
    font-size: 14px;
    font-family: "inter", sans-serif;
    color: #000 !important;
    height: 37px;
    padding: 23px 0;
    background-color: #fff !important;
    text-align: center;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .navbar-dark .navbar-nav .newNavLink {
    color: #000 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: "inter", sans-serif;
  }

  a.newNavLink {
    margin-left: 0;
    font-size: 14px;
    font-family: "inter", sans-serif;
    color: #000 !important;
    height: 37px;
    padding: 23px 0;
    background-color: #fff !important;
    text-align: center;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .navbar-dark .navbar-nav .newNavLink {
    color: #000 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: "inter", sans-serif;
  }

  a.newNavLink {
    margin-left: 0;
    font-size: 14px;
    font-family: "inter", sans-serif;
    color: #000 !important;
    height: 37px;
    padding: 23px 0;
    background-color: #fff !important;
    text-align: center;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1380px) {
  a.newNavLink {
    margin-left: 0px;
    font-size: 11px;
    font-family: "inter", sans-serif;
    color: #000;
    height: 37px;
  }
}
.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #001c3d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.chat-button:hover {
  background-color: #255ba3;
}

.chat-button i {
  font-size: 30px;
}

.chat-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10010;
  display: flex;
  flex-direction: column;
}

.chat-header {
  background-color: #001c3d;
  color: white;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header h4 {
  margin: 0;
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f4f4f4;
}

.chat-footer {
  padding: 10px;
  border-top: 1px solid #ddd;
  display: flex;
}

.chat-footer input {
  width: calc(100% - 50px);
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chat-footer button {
  padding: 8px 15px;
  background-color: #001c3d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .chat-button {
    width: 50px;
    height: 50px;
  }

  .chat-popup {
    width: 90%;
    right: 5%;
    bottom: 70px;
    height: 350px;
  }
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f4f4f4;
}

.chat-message {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.user-message {
  align-self: flex-end;
  max-width: 80%;
  margin-right: 10px;
}

.support-message {
  align-self: flex-start;
  max-width: 80%;
  margin-left: 10px;
}

.message-bubble {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  color: white;
  background-color: #001c3d; /* User message background */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.user-message .message-bubble {
  background-color: #001c3d; /* User message background */
}

.support-message .message-bubble {
  color: #000;
  background-color: #d1e7ff; /* Support message background */
}

.timestamp {
  font-size: 10px;
  color: #888;
  margin-top: 5px;
  text-align: right;
}

.support-message .timestamp {
  text-align: left;
}

.message-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}

.arrow-right {
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #001c3d;
  right: -10px;
  top: 10px;
}

.arrow-left {
  border-width: 10px 10px 10px 0;
  border-color: transparent #d1e7ff transparent transparent;
  left: -10px;
  top: 10px;
}

/* .chat-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f4f4f4;
  } */

/* .chat-message {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  } */

.user-message {
  align-self: flex-end;
  max-width: 100%;
  margin-right: 10px;
}

.support-message {
  align-self: flex-start;
  max-width: 100%;
  margin-left: 10px;
}

.message-bubble {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  background-color: #001c3d; /* User message background */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.user-message .message-bubble {
  background-color: #001c3d; /* User message background */
}

.support-message .message-bubble {
  background-color: #d1e7ff; /* Support message background */
}

.timestamp {
  font-size: 10px;
  color: #888;
  margin-top: 5px;
  text-align: right;
}

.support-message .timestamp {
  text-align: left;
}

.message-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}

.arrow-right {
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #001c3d;
  right: -10px;
  top: 10px;
}

.arrow-left {
  border-width: 10px 10px 10px 0;
  border-color: transparent #d1e7ff transparent transparent;
  left: -10px;
  top: 10px;
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .chat-button {
    width: 55px;
    height: 55px;
  }

  .chat-popup {
    width: 85%;
    right: 7.5%;
    bottom: 80px;
    height: 370px;
  }
}

.logo-img2 {
  max-width: 200px;
  margin: auto;
}

.logo-img3 {
  max-width: 104px;
  margin: auto;
}

.stripe-container {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

.gap-3 {
  gap: 1rem;
}

.li-left-space {
  margin-left: 1rem;
}

.height-35 {
  height: 39px;
}
