.body {
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
  line-height: 1.7em;
  font-weight: 400;
  font-size: 16px;
  color: #4e4e4e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #001c3d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 42px;
  line-height: 1.7em;
}

h2 {
  font-size: 36px;
  color: #001c3d;
  line-height: 1.7em;
}

h3 {
  font-size: 26px;
  color: #001c3d;
  line-height: 1.7em;
}

h4 {
  font-size: 24px !important;
  color: #22a3b3 !important;
  line-height: 1.7em !important;
  font-weight: 500 !important;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

img {
  width: 100%;
  height: auto;
  margin: auto;
}
ul li {
  font-family: "Poppins", sans-serif;
  line-height: 1.7em;
  font-weight: 400;
  font-size: 16px;
  color: #4e4e4e;
}

div#navbarSupportedContent {
  justify-content: end;
}

.bg-dark {
  background-color: #ffffff !important;
}

#logsedf {
  width: 65%;
}

.navbar-nav .nav-link {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.7em;
  padding: 6px 15px !important;
}

.navbar-brand {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000;
}

.active,
.nav-link:hover {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #22a3b3 !important;
}

.navbar-brand:hover {
  color: #22a3b3;
}

.tophead-social ul li a {
  color: #ffffff;
  font-size: 16px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}

.axistify-header {
  background-color: #001c3d;
  width: 100%;
  height: auto;
  padding: 0px 0px;
}

.tophead-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  float: left;
}

.header-top-nav.list-inline li {
  display: inline-block;
  font-size: 12px;
}

.header-top .header-top-nav .menu-item {
  padding: 0;
}

.element {
  float: right;
}

.element ul {
  margin-top: 0px;
  margin-bottom: 4px;
  color: #fff;
}

.element ul li a {
  color: #2eb8ba;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  padding: 0px 5px;
}

.header-top .header-top-nav .menu-item a {
  color: #fff;
  position: relative;
}

.ico-col {
  color: #30bfbf;
  /* font-size: 12px; */
  line-height: 25px;
}

.btn-primary {
  background-color: #001c3d;
  color: #fff;
  border: #001c3d;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #001c3d;
  border: 1px solid #001c3d;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #001c3d;
  border: 1px solid #001c3d;
}

.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
  background-color: #001c3d;
  border-color: #001c3d;
  color: #fff;
}

.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #001c3d;
  border-color: #001c3d;
  color: #fff;
}

.btn-secondary {
  background-color: #22a3b3 !important;
  color: #fff !important;
  border: #22a3b3 !important;
  text-transform: uppercase !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.active,
.btn-secondary:active,
.open > .dropdown-toggle.btn-secondary {
  background-color: #22a3b3 !important;
  color: #fff !important;
  border: 1px solid #22a3b3 !important;
}

.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary.focus:active,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open > .dropdown-toggle.btn-secondary.focus,
.open > .dropdown-toggle.btn-secondary:focus,
.open > .dropdown-toggle.btn-secondary:hover {
  background-color: #22a3b3 !important;
  border: 1px solid #22a3b3 !important;
  color: #fff !important;
}

.btn-secondary.active:not(:disabled):not(.disabled),
.btn-secondary:active:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle {
  background-color: #22a3b3 !important;
  border-color: #22a3b3 !important;
  color: #fff !important;
}

.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #22a3b3 !important;
  border-color: #22a3b3 !important;
  color: #fff !important;
}

.footer-section {
  background-color: #001c3d;
  padding: 20px 40px;
  width: 100%;
  height: auto;
}

.footer-logo {
  width: 60%;
}

.widget h2 {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  font-style: normal;
  line-height: 160.4%;
  text-transform: capitalize;
  text-decoration: underline;
}

.widget_content ul {
  padding: 0px 8px;
}

.widget_content ul li {
  list-style: none;
  padding: 3px 3px;
}

#foot-icon {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.widget_content ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  padding-left: 10px;
}
.widget_social ul li {
  display: inline;
}
.widget_social ul li a {
  color: #2eb8ba;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  padding-left: 10px;
}

.footerb-text p {
  color: #4e4e4e;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding-top: 10px;
}

.slider-section {
  width: 100%;
  height: auto;
  background: linear-gradient(260.17deg, #22a3b3 3.77%, #042b49 97.11%);
  padding: 100px 40px;
}

.sliderbg {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 6px;
}
.sliderbg h1 {
  font-size: 32px;
}
.sliderbg p {
  font-family: "Poppins", sans-serif;
  line-height: 1.7em;
  font-weight: 500;
  font-size: 18px;
  color: #4e4e4e;
}

.carousel-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 610px;
  /* Adjust the height as needed */
}

.slide1 {
  background-image: url("../images/banner-1.png");
}

.slide2 {
  background-image: url("../images/banner-2.png");
}

.slide3 {
  background-image: url("../images/banner-3.png");
}

.carousel-indicators {
  list-style: none;
  text-align: center;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
  margin: 0 5px;
  background-color: #ffffff;
  border-radius: 100%;
  cursor: pointer;
}

.carousel-indicators .active {
  background-color: #001c3dbf;
}
.banner2_text {
  margin-top: 200px;
  width: 75%;
  background-color: #001c3dbf;
  padding: 15px;
  border-radius: 5px;
}

.banner2_text h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.5em;
  color: #fff;
}
.abt-section {
  padding: 40px;
}
.abtimg img {
  width: 90%;
}
.serv-section {
  padding: 40px;
  background-color: #f5f5f5;
}
.serimg {
  text-align: end;
}
.serimg img {
  width: 90%;
}
.cardbg {
  background-color: #22a3b3;
  border-radius: 4px;
}
.bg-primary {
  background-color: #001c3d !important;
}
.bagetext span {
  color: #ffffff;
  font-weight: 700;
  font-size: 25px;
  padding: 5px 14px;
}
.cardbgbody {
  padding: 15px;
}
.cardicon {
  text-align: center;
}
.cardicon img {
  width: 50px;
}
.banner-section {
  background-image: url(../images/bannerimg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px;
}
.abtwel-text {
  text-align: center;
}
.abtwel-text p {
  color: #001c3d;
  font-weight: 500 !important;
}
.iconimg {
  text-align: center;
}
.iconimg img {
  width: 90px;
}
.cardtext h4 {
  font-size: 20px;
}
.logo-img {
  max-width: 300px;
  margin: auto;
}
.widget_content ul li a img {
  width: 140px;
}
.bookingtext p {
  text-align: center;
  font-weight: 600;
}
.bg-gray {
  background-color: #dedede;
}
.contact-form {
  background-color: #dcdcdc;
  border: solid 2px #378d9b;
  border-radius: 8px;
}
.logo-img2 {
  margin: auto;
  max-width: 200px;
}
.book-section {
  background-color: #30bfbf;
}

.rev i {
  color: #d1a700;
  font-size: 24px;
}
.revitext h2 {
  font-size: 50px !important;
}
.revitext h4 {
  font-size: 32px !important;
}

.owl-carousel .owl-nav {
  overflow: hidden;
  height: 0px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  display: none;
}
.owl-theme .owl-dots {
  display: none;
}

.owl-carousel .item {
  text-align: center;
}

.owl-carousel .nav-button {
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: absolute;
  top: 130px !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0.25;
}

.owl-carousel .owl-prev {
  left: -60px;
}

.owl-carousel .owl-next {
  right: -25px;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #4f4f4f;
  font-size: 25px;
  background: #ededed;
  border-radius: 5px;
}

.owl-carousel .prev-carousel:hover {
  background-position: 0px -53px;
}

.owl-carousel .next-carousel:hover {
  background-position: -24px -53px;
}

.owl-theme .owl-nav [class*="owl-"] {
  padding: 0px 7px;
}
.testbg {
  background-color: #ededed;
  /* box-shadow: 0px 0px 3px 2px #00000040; */
  padding: 25px;
  border-radius: 8px;
  width: 90%;
}
.textem-text h5 {
  color: #22a3b3;
}
#moreText {
  display: none;
}
.sidebar_user_profile img {
  width: 60px !important;
  height: 60px;
  overflow: hidden;
  border-radius: 100%;
}
a {
  color: #001c3d;
}
a:hover {
  color: #001c3d;
}
.pricing-6-item {
  border: 1px solid #ddd;
  /* border: 2px solid #293e4a; */
  border-radius: 10px;
  /* box-shadow: 0 1px 5px 0 #293e4a; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px 30px 42px;
}
.pricing-6-item p:after {
  background: #0000;
  border-bottom: 1px dashed #1f72b0;
  content: "";
  display: table;
  height: 1px;
  left: 0;
  margin-top: 20px;
  position: relative;
  width: 100%;
}
.mt-30 {
  margin-top: -100px;
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  a.nav-link {
    margin-left: 0;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #ffffff !important;
    height: 37px;
    padding: 23px 0;
    background-color: #001c3d !important;
    text-align: center;
  }

  #logsedf {
    width: 55%;
  }

  .tophead-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
  }

  .element ul li a {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    padding: 0px 5px;
  }
  .banner2_text h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 33px;
    line-height: 1.5em;
    color: #fff;
  }
  .banner2_text {
    margin-top: 70px;
    width: 100%;
    background-color: #001c3dbf;
    padding: 15px;
    text-align: center;
  }
  .carousel-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 350px;
  }
  .abt-section {
    padding: 20px;
  }
  .abtimg img {
    width: 100%;
  }
  .serimg img {
    margin-top: 20px;
    width: 100%;
  }
  p {
    font-family: "Poppins", sans-serif !important;
    line-height: 1.7em !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #4e4e4e !important;
  }
  .serv-section {
    padding: 20px;
    background-color: #f5f5f5;
  }
  .footer-section {
    background-color: #001c3d;
    padding: 20px;
    width: 100%;
    height: auto;
  }
  h2 {
    font-size: 28px;
    color: #001c3d;
    line-height: 1.7em;
  }
  .banner-section {
    background-image: url(../images/bannerimg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
  }
  h1 {
    font-size: 35px;
    line-height: 1.7em;
  }
  .sliderbg h1 {
    font-size: 30px;
  }
  .mt-30 {
    margin-top: 0px;
  }
  .mt-md-30 {
    margin-top: -155px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .navbar .navbar-brand {
    width: 70%;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: center;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #fff !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  a.nav-link {
    margin-left: 0;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #ffffff !important;
    height: 37px;
    padding: 23px 0;
    background-color: #001c3d !important;
    text-align: center;
  }

  .navbar-brand {
    font-weight: 300;
    font-size: 10px;
  }

  #logsedf {
    width: 40%;
  }

  .tophead-text {
    display: none;
  }
  .banner2_text h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 33px;
    line-height: 1.5em;
    color: #fff;
  }
  .banner2_text {
    margin-top: 70px;
    width: 100%;
    background-color: #001c3dbf;
    padding: 15px;
    text-align: center;
  }
  .carousel-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 350px;
  }
  .abt-section {
    padding: 20px;
  }
  .abtimg img {
    width: 100%;
  }
  .serimg img {
    margin-top: 20px;
    width: 100%;
  }
  p {
    font-family: "Poppins", sans-serif !important;
    line-height: 1.7em !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #4e4e4e !important;
  }
  .serv-section {
    padding: 20px;
    background-color: #f5f5f5;
  }
  .footer-section {
    background-color: #001c3d;
    padding: 20px;
    width: 100%;
    height: auto;
  }
  h2 {
    font-size: 28px;
    color: #001c3d;
    line-height: 1.7em;
  }
  .banner-section {
    background-image: url(../images/bannerimg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
  }
  h1 {
    font-size: 35px;
    line-height: 1.7em;
  }
  .sliderbg h1 {
    font-size: 30px;
  }
  .slider-section {
    width: 100%;
    height: auto;
    background: linear-gradient(260.17deg, #22a3b3 3.77%, #042b49 97.11%);
    padding: 60px 25px;
  }
  .mt-30 {
    margin-top: 0px;
  }
  .mt-md-30 {
    margin-top: -155px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .navbar .navbar-brand {
    width: 70%;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: center;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #fff !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }

  a.nav-link {
    margin-left: 0;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #ffffff !important;
    height: 37px;
    padding: 23px 0;
    background-color: #001c3d !important;
    text-align: center;
  }

  .navbar-brand {
    font-weight: 300;
    font-size: 10px;
  }

  #logsedf {
    width: 50%;
  }

  .tophead-text {
    display: none;
  }

  .banner2_text h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5em;
    color: #fff;
  }
  .banner2_text {
    margin-top: 40px;
    width: 100%;
    background-color: #001c3dbf;
    padding: 10px;
    text-align: center;
  }
  .carousel-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
  }
  .abt-section {
    padding: 15px;
  }
  .abtimg img {
    width: 100%;
  }
  h2 {
    font-size: 25px;
    color: #001c3d;
    line-height: 1.7em;
  }
  p {
    font-family: "Poppins", sans-serif !important;
    line-height: 1.4em !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #4e4e4e !important;
  }
  .serv-section {
    padding: 15px;
    background-color: #f5f5f5;
  }
  h4 {
    font-size: 19px;
    color: #22a3b3;
    line-height: 1.7em;
    font-weight: 500;
  }
  .serimg img {
    width: 100%;
    margin-top: 20px;
  }
  h3 {
    font-size: 20px;
    color: #001c3d;
    line-height: 1.7em;
  }
  .footer-section {
    background-color: #001c3d;
    padding: 15px;
    width: 100%;
    height: auto;
  }
  h1 {
    font-size: 28px;
    line-height: 1.7em;
  }
  .banner-section {
    padding: 20px;
  }
  .owl-carousel .owl-prev {
    left: -25px;
  }
  .owl-carousel .owl-next {
    right: -25px;
  }
  .testbg {
    background-color: #ededed;

    padding: 25px;
    border-radius: 8px;
    width: 100%;
  }
  .owl-theme .owl-nav [class*="owl-"] {
    color: #4f4f4f;
    font-size: 25px;
    background: #ededed;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 2px #00000040;
  }
  .revitext h2 {
    font-size: 33px;
    text-align: center;
  }
  .revitext h4 {
    text-align: center !important;
  }
  .rev {
    text-align: center;
    margin-bottom: 20px;
  }
  .slider-section {
    width: 100%;
    height: auto;
    background: linear-gradient(260.17deg, #22a3b3 3.77%, #042b49 97.11%);
    padding: 20px 15px;
  }
  .sliderbg h1 {
    font-size: 24px;
  }
  .sliderbg p {
    font-family: "Poppins", sans-serif;
    line-height: 1.7em;
    font-weight: 500;
    font-size: 16px;
    color: #4e4e4e;
  }
  .mt-30 {
    margin-top: 0px;
  }
  .mt-md-30 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1380px) {
  a.nav-link {
    margin-left: 0px;
    font-size: 11px;
    font-family: "Poppins", sans-serif;
    color: #000;
    height: 37px;
  }

  #logsedf {
    width: 55%;
  }
  .banner2_text h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 34px;
    line-height: 1.5em;
    color: #fff;
  }
  .carousel-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 450px;
  }
}

.map-container {
  width: 100%;
  height: 240px;
  position: relative;
  overflow: hidden;
}

.reviews-section {
  text-align: center;
  font-family: Arial, sans-serif;
}

.rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.score {
  font-size: 2rem;
  color: #00bcd4;
}

.stars {
  color: #ffc107;
}

.review-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.arrow {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.review-cards {
  display: flex;
  overflow: hidden;
  gap: 20px;
  width: 80%;
}

.review-card {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: left;
}

.profile {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  background: #ff5722;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.review-card a {
  color: #00bcd4;
  text-decoration: none;
  font-weight: bold;
}

.abt-section {
  font-family: Arial, sans-serif;
  text-align: center;
}

.revitext h2 {
  font-size: 24px;
  margin-bottom: 5px;
}

.revitext h4 {
  font-size: 20px;
  /* color: #ff5722; */
}

.rev i {
  color: #ffc107;
  margin: 0 2px;
}

.review-carousel {
  display: flex;
  gap: 20px;
  width: 100%;
  overflow: hidden;
}

.review-card {
  /* background-color: #f8f9fa; */
  border-radius: 10px;
  padding: 20px;
  width: 300px;
}

.rc-height {
  height: 422px !important;
}

@media screen and (max-width: 519px) {
  .review-card {
    /* background-color: #f8f9fa; */
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    min-width: 235px;
  }

  .rc-height {
    height: 370px !important;
  }

  .prev-btn {
    /* left: 15px; */
    font-size: 15px;
    position: relative;
    left: 8%;
    /* top: 50% !important; */
    border: 2px solid #dbdbdb;
  }

  .next-btn {
    /* left: 15px; */
    font-size: 15px;
    position: relative;
    left: -8%;
    /* top: 50% !important; */
    border: 2px solid #dbdbdb;
  }
}

@media screen and (min-width: 520px) and (max-width: 592px) {
  .review-card {
    /* background-color: #f8f9fa; */
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    min-width: 350px;
  }

  .rc-height {
    height: 335px !important;
  }
}

@media screen and (min-width: 593px) and (max-width: 768px) {
  .review-card {
    /* background-color: #f8f9fa; */
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    min-width: 434px;
  }

  .rc-height {
    height: 315px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1120px) {
  .review-card {
    /* background-color: #f8f9fa; */
    border-radius: 10px;
    padding: 20px;
    width: 50%;
  }

  .rc-height {
    height: 381px !important;
  }
}

.arrow {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.arrow:disabled {
  color: gray;
  cursor: not-allowed;
}

.sidebar_user_profile img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.arrow-left {
  height: 40px;
  width: 40px;
  cursor: pointer;
  /* position: absolute; */
  /* top: 130px !important; */
  top: 50% !important;
  margin: 5px;
  padding: 4px 7px;
  background: #ededed;
  display: inline-block;
  cursor: pointer;
  /* color: #4f4f4f; */
  font-size: 25px;
  /* background: #ededed; */
  border-radius: 5px;
  padding: 0px 7px;
  /* background: #869791; */
  color: #4f4f4f;
  text-decoration: none;
}

.owl-carousel .owl-prev {
  left: -25px;
}

.owl-carousel .owl-next {
  right: -25px;
}

.testbg {
  background-color: #ededed;

  padding: 25px;
  border-radius: 8px;
  width: 100%;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #4f4f4f;
  font-size: 25px;
  background: #ededed;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 2px #00000040;
}

.revitext h2 {
  font-size: 33px;
  text-align: center;
}

.revitext h4 {
  text-align: center;
}

.rev {
  text-align: center;
  margin-bottom: 20px;
}

.slider-section {
  width: 100%;
  height: auto;
  background: linear-gradient(260.17deg, #22a3b3 3.77%, #042b49 97.11%);
  padding: 20px 15px;
}

.sliderbg h1 {
  font-size: 24px;
}

.sliderbg p {
  font-family: "Poppins", sans-serif;
  line-height: 1.7em;
  font-weight: 500;
  font-size: 16px;
  color: #4e4e4e;
}

.mt-30 {
  margin-top: 0px;
}

.mt-md-30 {
  margin-top: 0px;
}
.nav-tabs {
  display: flex;
  padding: 8px;
  width: 100%;
}
.nav-tabs .nav-link {
  border-radius: 50px;
  padding: 5px 40px;
  font-weight: 500;
}

.nav-tabs .nav-link.active {
  background-color: #22a3b3 !important;
  color: #fff !important;
}

.nav-tabs .nav-link {
  background-color: #fff !important;
  color: #001c3d !important;
}

@media only screen and (min-width: 992px) and (max-width: 1380px) {
  a.nav-link {
    margin-left: 0px;
    font-size: 11px;
    font-family: "Poppins", sans-serif;
    color: #000;
    height: 37px;
  }

  #logsedf {
    width: 55%;
  }

  .banner2_text h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 34px;
    line-height: 1.5em;
    color: #fff;
  }

  .carousel-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 450px;
  }
  .nav-tabs {
    background-color: #f5f5f5;
    padding: 8px;
    border: 1px solid #ece4e4;
    width: 70%;
    border-radius: 100px;
  }
}
